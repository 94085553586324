#memberManager_Parent {
  position: fixed;
  width: 14vw;
  min-width: 14vw;
  max-width: 24vw;
  min-height: 38.5vh;
}

#memberManager_Title {
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  height: 2em;
  background: rgba(48, 48, 48, 0.98);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-size: calc(4px + 0.8vh);
}

#memberManager_TitleValue {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-left: 2em;
}

#memberManager_CloseBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5em;
  width: 1.5em;
  height: 1.33em;
  color: rgba(128, 128, 128, 0.75);
  border: rgba(128, 128, 128, 0) solid 1px;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
}

#memberManager_CloseBtn:hover {
  color: rgba(192, 192, 192, 0.9);
  border: rgba(128, 128, 128, 0.75) solid 1px;
}

#memberManager_Content {
  display: grid;
  user-select: none;
  background: rgba(80, 80, 80, 0.98);
  padding: 0.5em 0.5em;
  height: 100%;
  grid-template-rows: 2vh auto;
  grid-template-columns: auto 16px;
}

#memberManager_Footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 2em;
  background: rgba(48, 48, 48, 0.98);
  font-size: calc(4px + 0.8vh);
}

#memberManager_ResizeButton {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 0.2em;
  width: 1.5em;
  height: 1.33em;
  color: rgba(128, 128, 128, 0.75);
  border: rgba(128, 128, 128, 0) solid 1px;
  border-radius: 4px;
  cursor: se-resize;
  user-select: none;
  border-radius: 0;
  border-bottom: solid 1px rgba(128, 128, 128, 0.75);
  border-right: solid 1px rgba(128, 128, 128, 0.75);
}

#memberManager_OuterContainer {
  width: 100%;
  height: 100%;
  border-top: solid 1px rgba(128, 128, 128, 1);
  border-left: solid 1px rgba(128, 128, 128, 1);
  border-bottom: solid 1px rgba(128, 128, 128, 1);
  background: rgba(24, 24, 24, 0.75);
  overflow: hidden;
  grid-row-start: 2;
  grid-column-start: 1;
}

#memberManager_InnerContainer {
  display: block;
  grid-template-columns: 1fr 16px;
  grid-template-rows: 1fr;
  overflow: hidden;
}

.memberManager_InputObject {
  border: solid 1px rgba(24, 24, 24, 0.75);
  background-color: rgba(128, 128, 128, 1);
  color: white;
  font-size: 1em;
  letter-spacing: 1px;
  padding: 2px 2px 2px 0px;
  width: 100%;
  grid-column: 1/3;
}

#memberManager_ScrollContainer {
  width: 16px;
  height: 100%;
  border-top: solid 1px rgba(128, 128, 128, 1);
  border-right: solid 1px rgba(128, 128, 128, 1);
  border-bottom: solid 1px rgba(128, 128, 128, 1);
  background: rgba(112, 112, 112, 1);
  overflow: hidden;
  grid-row-start: 2;
  grid-column-start: 2;
}

#memberManager_ScrollButton {
  min-height: 3vh;
  width: calc(100% -1px);
  background-color: rgb(80, 80, 80);
  border: rgba(255, 255, 255, 0.75) solid 1px;
}

.memberManager_CrewOuterContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 4em;
  min-width: 12vw;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  border: solid 1px rgba(128, 128, 128, 1);
}

.memberManager_CrewFloatContainer {
  position: absolute;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  border: solid 1px rgba(128, 128, 128, 1);
}

.memberManager_CrewOuterContainer:hover {
  background: rgba(64, 64, 64, 1);
}

.memberManager_CrewInnerContainer {
  display: grid;
  width: 100%;
  grid-template-rows: auto auto;
  grid-template-columns: 3em auto 1fr auto;
}

.memberManager_Avatar {
  max-height: 2.25em;
  margin: 0.25em;
  grid-column: 1;
  grid-row: 1/3;
}

.memberManager_Handle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1em;
  font-weight: 400;
  grid-row-start: 1;
  grid-column: 2;
}

.memberManager_Moniker {
  display: flex;
  justify-content: flex-start;
  align-items: top;
  font-size: 0.75em;
  grid-row-start: 2;
  grid-column: 2;
  color: rgba(160, 160, 160, 1);
}

.memberManager_Rank {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-column: 3;
  padding-right: 0.5em;
}

.memberManager_Roles {
  display: flex;
  justify-content: flex-end;
  align-items: top;
  grid-column: 3;
  grid-row: 2;
  padding-right: 0.5em;
  font-size: 0.8em;
  white-space: nowrap;
  overflow: hidden;
  color: limegreen;
}
