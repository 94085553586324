.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #282c34ff;
  text-align: left;
  font-size: calc(5px + 0.6vmin);
  font-weight: 100;
  letter-spacing: 1px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: white;
}

.header_MenuItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 1.75vh;
  user-select: none;
  padding-left: 0.25vw;
  padding-right: 0.25vw;
  border: solid 0 rgba(255, 255, 255, 0);
}

.header_MenuItem:hover {
  border: solid 0 rgba(255, 255, 255, 0);
  background-color: rgba(255, 255, 255, 0.125);
}

.header_DialogContainer {
  position: fixed;
  top: 3.25vh;
  left: 0;
  display: grid;
  visibility: hidden;
  min-width: 8vw;
  background-color: #282c34ff;
}

.header_DialogLineItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  user-select: none;
  min-height: 1.75vh;
  padding-left: 0.15vw;
  padding-right: 0.15vw;
  border-top: solid 1px rgba(255, 255, 255, 0.2);
}

.header_DialogLineItem:hover {
  background-color: rgba(255, 255, 255, 0.125);
}
