.colorPicker_Parent {
  display: grid;
  grid-template-rows: auto auto auto auto;
  grid-template-columns: auto 72% auto;
  background-color: rgba(32, 32, 32, 0.5);
  min-height: 5vh;
  min-width: 400px;
  border: rgba(0, 0, 0, 0.25) solid 3px;
}

.colorPicker_Preview {
  grid-row-start: 1;
  grid-row-end: 4;
  grid-column-start: 1;
  grid-column-end: 1;
  background-color: black;
  margin: 0.25em auto;
  height: 48px;
  width: 48px;
  border: rgba(221, 221, 221, 0.66) solid 1px;
}

.colorPicker_R,
.colorPicker_G,
.colorPicker_B,
.colorPicker_A {
  /*background-color: rgba(221, 221, 221, 0.33);*/
  border: rgba(221, 221, 221, 0.33) solid 1px;
  min-height: 24px;
  margin: 0.25em 0em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.colorPicker_R {
  grid-column-start: 2;
  grid-row-start: 1;
}

.colorPicker_G {
  grid-column-start: 2;
  grid-row-start: 2;
}

.colorPicker_B {
  grid-column-start: 2;
  grid-row-start: 3;
}

.colorPicker_A {
  grid-column-start: 2;
  grid-row-start: 4;
}

.colorPicker_R_Input,
.colorPicker_G_Input,
.colorPicker_B_Input,
.colorPicker_A_Input {
  width: 36px;
  margin: 0.6em auto 0.6em auto;
  border: none;
  padding-left: 4px;
  background-color: rgba(128, 128, 128, 1);
  color: white;
}

.colorPicker_R_Input {
  grid-column-start: 3;
  grid-row-start: 1;
}

.colorPicker_G_Input {
  grid-column-start: 3;
  grid-row-start: 2;
}

.colorPicker_B_Input {
  grid-column-start: 3;
  grid-row-start: 3;
}

.colorPicker_A_Input {
  grid-column-start: 3;
  grid-row-start: 4;
}

.colorPicker_SliderBackground {
  min-height: 0.6em;
  width: 95%;
  border-radius: 5px;
}

.colorPicker_SliderButton {
  min-height: 0.475em;
  width: 25px;
  background-color: rgb(92, 92, 92);
  border-radius: 0px;
  border: rgba(255, 255, 255, 0.75) solid 1px;
  position: absolute;
  user-select: none;
}

.colorPicker_SliderButton:hover {
  background-color: orange;
  cursor: pointer;
}
