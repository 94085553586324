.divisionContainer_Parent {
  display: grid;
  grid-template-rows: 1.2vh 1.33vh 0.25em auto;
  grid-template-columns: 0 0.25em 0.5vw auto;
}

.divisionContainer_Tile {
  border: 4px solid;
  border-radius: 10px;
  border-bottom-left-radius: 0;
  display: grid;
  grid-template-columns: 0.65vw auto auto;
  grid-template-rows: 2em auto auto;
  font-size: calc(8px + 0.5vh);
  white-space: nowrap;
  min-height: 10vh;
}

.divisionContainer_Title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom-right-radius: 0.5em;
  font-variant-caps: normal;
  padding: 0.25em 0.25em;
  user-select: none;
  letter-spacing: 0.025em;
}
