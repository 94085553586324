footer {
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
}

.Footer {
  min-height: 3vh;
  display: flex;
  background-color: #10121b;
  font-size: calc(4px + 0.75vmin);
  align-items: center;
  justify-content: center;
}

.Footer-left,
.Footer-center,
.Footer-right {
  display: inline-flex;
  min-width: 33%;
  min-height: 2.5vh;
  border: 0px lime solid;
  align-items: center;
}

.Footer-left {
  justify-content: flex-start;
}

.Footer-center {
  justify-content: center;
}

.Footer-right {
  justify-content: flex-end;
}

.Footer-powered {
  height: 1.75vmin;
  cursor: pointer;
}
