#popupContainer_Parent {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0);
  display: none;
}

#popupContainer_Mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  display: none;
}

#popupContainer_Title,
#popupContainer_Content {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 720px;
  user-select: none;
}

#popupContainer_Title {
  margin-top: 15vh;
  height: 2em;
  background: rgba(48, 48, 48, 0.98);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

#popupContainer_TitleValue {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-left: 2em;
}

#popupContainer_CloseBtn {
  margin-right: 0.5em;
  width: 1.5em;
  height: 1.33em;
  color: rgba(128, 128, 128, 0.75);
  border: rgba(128, 128, 128, 0) solid 1px;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
}

#popupContainer_CloseBtn:hover {
  color: rgba(192, 192, 192, 0.9);
  border: rgba(128, 128, 128, 0.75) solid 1px;
}

#popupContainer_Content {
  min-height: 25vh;
  background: rgba(80, 80, 80, 0.98);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 2em 0em;
}

.popupContainer_InputTable {
  display: grid;
  grid-gap: 1.5vh;
  grid-template-columns: auto auto;
  max-width: 760px;
}

#popupContainer_ErrorMessage {
  grid-column: 1 /3;
  font-size: 0.9em;
  color: rgb(255, 92, 92);
  background: none;
  padding: 0.5em;
  align-content: center;
  justify-content: center;
}

.pupupContainer_InputLabel {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 180px;
}

.pupupContainer_InputObject {
  border: none;
  background-color: rgba(128, 128, 128, 1);
  color: white;
  font-size: 1em;
  letter-spacing: 1px;
  padding: 2px 0px 2px 5px;
  min-width: 280px;
  max-width: 500px;
}

.popupContainer_ButtonRow {
  grid-column-start: 1;
  grid-column-end: 3;
}

.popupContainer_Button {
  margin: 0.5em 1em;
  display: inline-flex;
  width: 5vw;
  height: 3vh;
  justify-content: center;
  align-items: center;
  border: rgba(160, 160, 160, 1) solid 1px;
  cursor: pointer;
}

.popupContainer_Button:hover {
  background-color: rgba(160, 160, 160, 0.5);
}

a {
  color: white;
  text-decoration: none;
}
