.branchControl_Container {
  grid-column: 3/3;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 0.2em;
}

.branchControl_Button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 1.4em;
  border-radius: 5px;
  border: rgba(0, 0, 0, 0) solid 1px;
  user-select: none;
  cursor: pointer;
  padding-bottom: 0.1em;
}
