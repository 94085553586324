#commandContainer_Parent {
  display: grid;
  grid-gap: 1vh;
  grid-template-rows: auto auto;
  margin: 4vh 0.5vw 0 0.5vw;
  padding: 0.25vh 0;
  background-color: rgba(24, 192, 255, 0);
  border-radius: 4px;
  cursor: default;
}

#commandContainer_Title {
  grid-column-start: 1;
  grid-row-start: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(160, 224, 255);
  color: rgba(32, 32, 32);
  font-weight: bold;
  font-size: calc(8px + 0.5vh);
  border-radius: 8px;
  padding-left: 2em;
  user-select: none;
}

#commandConatiner_AddBranch {
  width: 1.5em;
  margin-right: 0.25em;
  padding-bottom: 0.2em;
  border-radius: 5px;
  border: rgba(0, 0, 0, 0) solid 1px;
  user-select: none;
}

#commandConatiner_AddBranch:hover {
  color: rgb(0, 120, 180);
  border: rgb(85, 190, 243) solid 1px;
  cursor: pointer;
}

#commandConatiner_BranchesParent {
  grid-row-start: 2;
  display: grid;
  grid-auto-columns: 1fr;
  grid-gap: 1vh;
}

.commandContainer_Branch {
  border: 4px solid;
  border-radius: 10px;
  border-bottom-left-radius: 0;
  display: grid;
  grid-template-columns: 0.65vw auto auto;
  grid-template-rows: 2em auto auto;
  font-size: calc(4px + 1vh);
}

.commandContainer_BranchTitle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom-right-radius: 0.5em;
  font-variant-caps: normal;
  padding: 0.25em 0.25em;
  user-select: none;
  letter-spacing: 0.025em;
  grid-column: 1/3;
}

.commandContainer_PersonnelParent {
  grid-column: 2/4;
  grid-row: 2/4;
  border-top-left-radius: 0.5em;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  grid-gap: 0.5em;
  padding: 0.5em 1em 0.25em 0.5em;
}

.commandContainer_PersonnelEntryOuter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px;
  padding: 1px 0;
  font-size: calc(2px + 1vh);
  user-select: none;
}

.commandContainer_PersonnelEntryInner {
  min-width: calc(100% - 0.66em);
  min-height: 3.25vh;
  border-radius: 5px;
  margin-left: 0;
}
