#missionManager_Parent {
  position: fixed;
  width: 14vw;
  min-width: 32vw;
  max-width: 36vw;
  min-height: 32vh;
  max-height: 48vh;
}

#missionManager_Title {
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  height: 2em;
  background: rgba(48, 48, 48, 0.98);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-size: calc(4px + 0.8vh);
}

#missionManager_TitleValue {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-left: 2em;
}

#missionManager_CloseBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5em;
  width: 1.5em;
  height: 1.33em;
  color: rgba(128, 128, 128, 0.75);
  border: rgba(128, 128, 128, 0) solid 1px;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
}

#missionManager_CloseBtn:hover {
  color: rgba(192, 192, 192, 0.9);
  border: rgba(128, 128, 128, 0.75) solid 1px;
}

#missionManager_Content {
  display: grid;
  user-select: none;
  background: rgba(80, 80, 80, 0.98);
  padding: 0.5em 0.5em;
  height: 100%;
  grid-template-rows: 2vh auto;
  grid-template-columns: auto 16px;
}

#missionManager_Footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 2em;
  background: rgba(48, 48, 48, 0.98);
  font-size: calc(4px + 0.8vh);
}

#missionManager_ResizeButton {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 0.2em;
  width: 1.5em;
  height: 1.33em;
  color: rgba(128, 128, 128, 0.75);
  border: rgba(128, 128, 128, 0) solid 1px;
  border-radius: 4px;
  cursor: se-resize;
  user-select: none;
  border-radius: 0;
  border-bottom: solid 1px rgba(128, 128, 128, 0.75);
  border-right: solid 1px rgba(128, 128, 128, 0.75);
}

#missionManager_OuterContainer {
  width: 100%;
  height: 100%;
  border-top: solid 1px rgba(128, 128, 128, 1);
  border-left: solid 1px rgba(128, 128, 128, 1);
  border-bottom: solid 1px rgba(128, 128, 128, 1);
  background: rgba(24, 24, 24, 0.75);
  overflow: hidden;
  grid-row-start: 2;
  grid-column-start: 1;
}

#missionManager_InnerContainer {
  display: block;
  grid-template-columns: 1fr 16px;
  grid-template-rows: 1fr;
  overflow: hidden;
}
