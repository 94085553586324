.crewContainer_Parent {
  display: grid;
  grid-template-rows: 1.5vh 1.33vh 0.25em auto;
  grid-template-columns: 0 0.25em 0.9vw auto;
}

.crewContainer_Tile {
  border: 0;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 3.25vw auto auto;
  grid-template-rows: 2em auto auto;
  font-size: calc(6px + 1vh);
  white-space: nowrap;
  grid-gap: 0.33em;
}

.crewContainer_PersonnelEntryOuter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px;
  padding: 0 0.25em 0 0;
  font-size: calc(2px + 1vh);
  user-select: none;
  grid-column-start: 1;
  grid-column-end: 4;
}

.crewContainer_PersonnelEntryInner {
  min-width: calc(100% - 0.75vw);
  min-height: 4.25vh;
  border-radius: 8px;
  display: grid;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  grid-template-columns: auto auto 1fr auto;
  grid-template-rows: 1fr auto 1fr;
}

.crewContainer_MemberCardAvatar {
  display: flex;
  border-radius: 6px;
  grid-row-start: 1;
  grid-row-end: 4;
  margin-left: 0.33vh;
  margin-right: 0.33vh;
}

.crewContainer_MemberCardName {
  color: rgb(122, 188, 255);
  font-size: 1.2em;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 1px;
}

.crewContainer_MemberCardRank {
  color: white;
  font-size: 0.8em;
  font-weight: bold;
  letter-spacing: 0.05em;
}

.crewContainer_MemberCardControl {
  display: flex;
  font-size: 0.6em;
  justify-content: flex-end;
  margin: 0 0.5em;
}

.crewContainer_MemberCardControl:hover {
  cursor: pointer;
  color: white;
}
